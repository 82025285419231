import "../../../App.css";
import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoCenter from "../components/LogoCenter";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
import ScrollToDown from "components/scroll/ScrollToDown";
//import ChangeTextSize from "components/changeText/ChangeTextSize";
import DrawerRight from "../components/DrawerRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import axios from "axios";
import { useHistory } from "react-router-dom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;

export const Welcome = () => {
  const history = useHistory();
  const [servingPeriod, setServingPeriod] = useState([]);

  useEffect(() => {
    document.title = "Welcome";
    ReactGA.initialize("UA-172332025-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const url =
      "https://ecommv2.servingintel.com/items/serving-period/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId;
    axios
      .get(url)
      .then((response) => {
        setServingPeriod(response.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className="Welcome">
      <LogoCenter />
      <BlankSpace />
      <DrawerRight />
      {/*<Container>
        <Row>
          <div className="center">
            <div className="f-title">
              <h4>
                Good day <b>Shirley</b>. Your <b>Meal Credit Available</b> is
                <Typography variant="h1" component="div" align="center">
                  {" "}
                  30{" "}
                </Typography>
              </h4>
            </div>
          </div>
          <BlankSpace />
        </Row>
      </Container>*/}

      <Container fluid="md">
        <Row>
          {/*<Col xs={12} sm={4} className="blankspace center">
            <Card>
              <CardActionArea href="/menu">
                <CardMedia
                  component="img"
                  height="140"
                  image="/static/breakfast.png"
                  alt="Breakfast"
                />
                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography gutterBottom variant="h4" component="div">
                    Breakfast
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card> 
          </Col>*/}
          {servingPeriod.map((item) => {
            return (
              <>
                <Col xs={12} sm={6} className="blankspace center">
                  <Card>
                    <CardActionArea
                      onClick={() => {
                        history.push({
                          pathname: "/menu",
                          state: { servingPeriod: item.PeriodName },
                        });
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image="/static/lunch.png"
                        alt="Lunch"
                      />
                      <CardContent sx={{ paddingTop: 0 }}>
                        <Typography gutterBottom variant="h4" component="div">
                          {item.PeriodName}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
      <BlankSpace />
      <BlankSpace />
      <BottomNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      {/* <ChangeTextSize /> */}
      <ScrollToDown />
    </div>
  );
};
