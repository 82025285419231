import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/config";
import FoodCard from "../components/FoodCard";
import {
  Grid,
  Button,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  ImageList,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LowerNav } from "../navigation/NavBar";
import parseHtml from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Link, scroller } from "react-scroll";
import { CustomerChat } from "../CustomerChat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation, Route } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";

//import { setRestaurantProducts } from "../../../redux/publicReducer/actions";
import { setSearch } from "../../../redux/publicReducer/actions";
import { setLoading } from "../../../redux/publicReducer/actions";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import LogoCenter from "../components/LogoCenter";
import BottomMenuNav from "../components/BottomMenuNav";
import TopWelcomeNav from "../components/TopWelcomeNav";
import DrawerRight from "../components/DrawerRight";

import ScrollToDown from "components/scroll/ScrollToDown";
import ScrollToUp from "components/scroll/ScrollToUp";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FontSizeChanger from "react-font-size-changer";
import { enable } from "workbox-navigation-preload";

const serverId = process.env.REACT_APP_SERVER_ID;
const site = process.env.REACT_APP_SITE;
const siteId = process.env.REACT_APP_SITE_ID;
const siteImgSrc = process.env.REACT_APP_IMAGE_SRC;
const enableRevenueCenter = process.env.REACT_APP_ENABLE_REVENUE_CENTER;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  ImageList: {
    display: "flex",
    height: "auto",
    color: "black",
    transform: "translateZ(0)",
    // padding: "0rem 400px",
    backgroundColor: "white",
    marginRight: "auto !important",
    marginLeft: "auto !important",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  dialogTitleBar: {
    margin: 0,
    padding: theme.spacing(2),
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const Restaurant = (props) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { state } = useLocation();
  const history = useHistory();
  const [availableProducts, setAvailableProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const { search, filterBy, currentRestaurant } = useSelector(
    (state) => state.public
  );
  const [setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [emptyCategory, setEmptyCategory] = useState([]);
  const [updateTime, setUpdateTime] = useState("");
  const { cartItems } = useSelector((state) => state.cart);
  const [chosenCategory, setChosenCategory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [categories, setCategories] = useState([]);
  const [openWelcome, setOpenWelcome] = useState(false);
  const [revenueCenter, setRevenueCenter] = useState(
    props.location.state.revenueCenter
  );
  const [removeItemsList, setRemoveItemsList] = useState([]);
  const [menuName, setMenuName] = useState(props.location.state.menuName);
  const categoriesRef = db
    .collection("admins")
    .doc(match.params.restaurantId)
    .collection("categories");

  const isMobile = useMediaQuery("(max-width:0px)");

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };

  const getSelectedProduct = (id) => {
    setSelectedProduct(id);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // useEffect(() => {
  //   if (state?.category && categories.length) {
  //     setChosenCategory(state.category.DepartmentID);
  //     scroller.scrollTo(state.category.DepartmentID, {
  //       duration: 1500,
  //       delay: 300,
  //       smooth: true,
  //       offset: -110,
  //     });
  //   }
  // }, [categories]);
  // console.log(categories);

  const addRemoveItems = (Id) => {
    setRemoveItemsList((removeItemsList) => [...removeItemsList, Id]);
  };

  useEffect(() => {
    if (
      !sessionStorage.getItem("pinCodeOk") &&
      sessionStorage.getItem("pinCodeOk") !== 1
    ) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (search && search !== "") {
      categories.map((category, i) => {
        const element = document.getElementById(category.DepartmentID);
        if (element) {
          const isImg = document
            .getElementById(category.DepartmentID)
            .getElementsByTagName("img");
          if (isImg) {
            if (isImg.length < 1) {
              document.getElementById(category.DepartmentID).style.display =
                "none";
            } else {
              document.getElementById(category.DepartmentID).style.display =
                "block";
            }
          }
        }
      });
    }
    if (!search || search == "") {
      categories.map((category, i) => {
        document.getElementById(category.DepartmentID).style.display = "";
      });

      scroller.scrollTo(chosenCategory, {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: -110,
      });
    }
  }, [search]);

  useEffect(() => {
    // const unsubscribe = db
    //   .collection("admins")
    //   .doc(match.params.restaurantId)
    //   .collection("products")
    //   .onSnapshot((snapshot) => {
    //     let _products = [];
    //     snapshot.docs.forEach((doc) => {
    //       _products.push({ ...doc.data(), id: doc.id });
    //     });
    //     setAvailableProducts(_products);
    //     dispatch(setRestaurantProducts(_products));
    //   });

    // return unsubscribe;

    dispatch(setLoading(true));
    const url =
      "https://ecommv2.servingintel.com/items/all/" +
      siteId +
      "?server_id=" +
      serverId;
    //const url = "/servingintel_api/public/items/all";
    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => {});
    dispatch(setLoading(false));
  }, [updateTime]);

  // const interval = setInterval(() => {
  //   axios.get("http://localhost/servingintel_api/public/items/all")
  //   .then((response) => {
  //     if(availableProducts !== response.data)
  //     {
  //       setAvailableProducts(response.data);

  //     }

  //   }).catch(error => {})
  // }, 60000);

  useEffect(() => {
    //const unsub = categoriesRef.onSnapshot((snap) => {

    // const _categories = snap.docs.map((doc) => {
    //   return { ...doc.data(), id: doc.id };
    // });

    //setCategories(_categories);
    //});

    //return unsub;
    const url =
      enableRevenueCenter == 1
        ? "https://ecommv2.servingintel.com/items/revenue-center-serving-categories/" +
          serverId +
          "/" +
          siteId +
          "/" +
          props.location.state.servingPeriod +
          "/" +
          revenueCenter +
          "/" +
          menuName
        : "https://ecommv2.servingintel.com/items/categories/" +
          siteId +
          "?server_id=" +
          serverId;
    //const url = "/servingintel_api/public/items/categories";
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {});
  }, [updateTime]);

  String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  // useEffect(() => {
  //   if (getCookie("sic_name")) {
  //     setOpenWelcome(true);
  //   }
  // }, []);

  useEffect(() => {
    // var verifyOrderProcess = new EventSource(
    //   "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
    //     siteId +
    //     "/" +
    //     getCookie("orderId") +
    //     "?server_id=" +
    //     serverId
    // );
    // verifyOrderProcess.onmessage = function (event) {
    //   if (event.data === 1) {
    //     verifyOrderProcess.close();
    //     setCookie("orderId", "");
    //   }
    // };

    var source2 = new EventSource(
      "https://ecommv2.servingintel.com/Items/checkUpdate?site_id=" + siteId
    );
    source2.onmessage = function (event) {
      var u_items = JSON.parse(event.data);
      //console.log(u_items[0].utime);
      if (u_items[0].time != "") {
        if (sessionStorage.getItem("update_date_time") !== u_items[0].time) {
          setUpdateTime(u_items[0].time);
          sessionStorage.setItem("update_date_time", u_items[0].time);
        }
      }
    };
  }, []);

  return (
    <React.Fragment>
      <FontSizeChanger
        targets={["#target .content"]}
        onChange={(element, newValue, oldValue) => {
          console.log(element, newValue, oldValue);
        }}
        options={{
          stepSize: 2,
          range: 5,
        }}
        customButtons={{
          up: (
            <AppBar
              position="fixed"
              sx={{
                width: "95px",
                boxShadow: 0,
                top: "auto",
                bottom: 237.5,
                backgroundColor: "transparent",
              }}
            >
              <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton>
                  <Fab
                    color="primary"
                    aria-hidden="true"
                    sx={{ backgroundColor: "#3D5309" }}
                  >
                    <AddCircleOutlineIcon />
                  </Fab>
                </IconButton>
              </Toolbar>
            </AppBar>
          ),
          down: (
            <AppBar
              position="fixed"
              sx={{
                width: "95px",
                boxShadow: 0,
                top: "auto",
                bottom: 180,
                backgroundColor: "transparent",
              }}
            >
              <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton>
                  <Fab
                    color="primary"
                    aria-hidden="true"
                    sx={{ backgroundColor: "#765F00" }}
                  >
                    <RemoveCircleOutlineIcon />
                  </Fab>
                </IconButton>
              </Toolbar>
            </AppBar>
          ),
        }}
      />

      <div
        id="target"
        style={{
          width: "100%",
          textOverflow: "ellipsis",
          position: "fixed",
          zIndex: "9",
          background: "white",
          top: "0px",
        }}
      >
        <LogoCenter />
        {/* <TopWelcomeNav /> */}
        <DrawerRight />

        {!isMobile ? (
          <ImageList className={classes.ImageList}>
            {categories.map((category) => {
              return (
                <Link
                  activeClass="active"
                  to={category.DepartmentID}
                  spy={true}
                  smooth={true}
                  offset={-310}
                  duration={300}
                  key={category.DepartmentID}
                  onClick={() => {
                    dispatch(setSearch(""));
                    setChosenCategory(category.DepartmentID);
                  }}
                  style={{
                    justifyContent: "center",
                    width: "auto",
                    height: "auto",
                    // whiteSpace: "pre-line",
                  }}
                >
                  <MenuItem
                    className="content"
                    style={{
                      display: "flex",
                      borderRadius: "5px",
                      backgroundColor: "#B56100",
                      //                        backgroundColor:
                      //                          chosenCategory === category.DepartmentName &&
                      //                          currentRestaurant?.restaurantColor?.hex,
                      color:
                        (chosenCategory === category.DepartmentName &&
                          "white") ||
                        "black",
                      textTransform: "none",
                      padding: ".5rem .5rem",
                      marginBottom: ".125rem",
                      marginRight: ".125rem",
                      textAlign: "center",
                      color: "white",
                      fontSize: "large",
                      fontFamily: "Helvetica",
                      // justifyContent: "center",
                      //FormControl: 'wrapped',
                      textOverflow: "ellipsis",
                      // whiteSpace: 'break-spaces',
                    }}
                  >
                    {category.DepartmentDescription.toProperCase()}
                  </MenuItem>
                </Link>
              );
            })}
          </ImageList>
        ) : null}

        <LowerNav />
      </div>

      <div
        className="OrderNow"
        style={{
          marginTop: "17%",
        }}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div
            style={{
              minHeight: "80vh",
              maxHeight: "100vh",
              minWidth: isMobile ? "80vw" : "30vw",
            }}
          >
            <List component="nav">
              <ListItem
                button
                onClick={() => {
                  dispatch({ type: "SET_FILTER", payload: "" });
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary="View Full Menu" />
              </ListItem>
              <Divider />
              {categories.map((category) => {
                return (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        dispatch({
                          type: "SET_FILTER",
                          payload: category.DepartmentName,
                        });
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemText primary={category.DepartmentDescription} />
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          </div>
        </Popover>

        {categories.map((category, i) => {
          if (search || !isMobile) {
            return (
              <div
                id={category.DepartmentID}
                style={{
                  margin: isMobile ? "1rem 0rem " : "3.5rem 0rem",
                  padding: "25px 1rem",
                }}
                key={category.DepartmentID}
                onPointerEnter={() => {
                  setChosenCategory(category.DepartmentID);
                }}
              >
                <div id="target">
                  <Typography
                    className="content"
                    style={{
                      fontSize: "21px",
                      fontWeight: "bold",
                      // marginTop: "20rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {category.DepartmentDescription}
                  </Typography>

                  <h2
                    className="content"
                    style={{
                      marginBottom: "1rem",
                      lineHeight: "16px",
                      fontSize: "18px",
                    }}
                  >
                    {category.WebDescription}
                  </h2>
                  {isMobile && <Divider />}
                  <Grid className="content" container gap={3}>
                    {availableProducts.map((product) => {
                      const isIncluded =
                        product.Department == category.DepartmentName ? 1 : 0;
                      if (
                        isIncluded &&
                        (!search ||
                          product.ItemDescription.toLowerCase().includes(
                            search?.toLowerCase()
                          ) ||
                          product.ItemName.toLowerCase().includes(
                            search?.toLowerCase()
                          ))
                      ) {
                        return (
                          <Grid
                            xs={12}
                            sm={4}
                            lg={2}
                            gap={1}
                            key={product.ItemID}
                            hidden={
                              removeItemsList.includes(product.ItemID) == true
                                ? true
                                : false
                            }
                          >
                            <FoodCard
                              getSelectedProduct={getSelectedProduct.bind(this)}
                              product={product}
                              revenueCenter={revenueCenter}
                              menuName={menuName}
                              addRemoveItems={addRemoveItems.bind(this)}
                              //restaurantId={match.params.restaurantId}
                            />
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                </div>
              </div>
            );
          } else {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    {category.DepartmentDescription.toProperCase()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <h2
                      style={{
                        marginBottom: "1rem",
                        lineHeight: "19px",
                        fontSize: "16px",
                      }}
                    >
                      {category.WebDescription}
                    </h2>
                  </Typography>

                  {isMobile && <Divider />}
                  <Grid container gap={1}>
                    {availableProducts.map((product) => {
                      const isIncluded =
                        product.Department == category.DepartmentName ? 1 : 0;
                      if (
                        isIncluded &&
                        (!search ||
                          product.ItemDescription.toLowerCase().includes(
                            search?.toLowerCase()
                          ))
                      ) {
                        return (
                          <Grid
                            xs={12}
                            sm={6}
                            lg={4}
                            gap={1}
                            key={product.ItemID}
                            hidden={
                              removeItemsList.includes(product.ItemID) == true
                                ? true
                                : false
                            }
                          >
                            <FoodCard
                              getSelectedProduct={getSelectedProduct.bind(this)}
                              product={product}
                              revenueCenter={revenueCenter}
                              menuName={menuName}
                              addRemoveItems={addRemoveItems.bind(this)}
                              //restaurantId={match.params.restaurantId}
                            />
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}

        {availableProducts.some(
          (product) =>
            product.Department.length === 0 &&
            (!search ||
              product.ItemDescription?.toLowerCase()?.includes(
                search.toLowerCase()
              ))
        ) &&
          !filterBy && (
            <div id="target" style={{ margin: "3.5rem 0rem", padding: "1rem" }}>
              <Typography
                className="content"
                variant="h5"
                style={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                Uncategorized
              </Typography>

              <Grid className="content" container gap={3}>
                {availableProducts.map((product) => {
                  if (
                    !product.Department.length &&
                    (!search ||
                      product.ItemDescription.toLowerCase().includes(
                        search.toLowerCase()
                      ))
                  ) {
                    return (
                      <Grid className="content" xs={12} sm={6} lg={4} gap={1}>
                        <FoodCard
                          getSelectedProduct={getSelectedProduct.bind(this)}
                          product={product}
                          // restaurantId={match.params.restaurantId}
                        />
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })}
              </Grid>
            </div>
          )}

        <Dialog
          open={openWelcome}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Welcome Back!"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ "text-align": "center" }}
              id="alert-dialog-description"
            >
              <b>{getCookie("sic_name")}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ "text-align": "center", display: "block" }}>
            <Button onClick={handleCloseWelcome} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.toolbar} />
      <BottomNavRestaurant />
      <Route exact path="/menu/:ItemDescription">
        <ProductDetails
          updateTime={updateTime}
          productId={selectedProduct}
          revenueCenter={revenueCenter}
          menuName={menuName}
        />
        ;
      </Route>
      <BottomMenuNav sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} />
      {/* <ChangeTextSize /> */}
      <ScrollToDown />
      <ScrollToUp />
    </React.Fragment>
  );
};

export const ProductDetails = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [updateTime, setUpdateTime] = useState(props.updateTime);
  const [defaultChange, setDefaultChange] = useState();
  const [defaultItem, setDefaultItem] = useState();
  const [defaultMod, setDefaultMod] = useState();
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [modifiers, setModifiers] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 0px)");
  // const productRef = db
  //   .collection("admins")
  //   .doc(match.params.restaurantId)
  //   .collection("products")
  //   .doc(match.params.productId);

  //console.log(match, "matches modal");
  const { cartItems } = useSelector((state) => state.cart);
  const { state } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [productRating, setProductRating] = useState();
  const [restaurantFavorites, setRestaurantFavorites] = useState([]);
  const [required, setRequired] = useState([]);

  useEffect(() => {
    // const unsub = productRef.onSnapshot((snapshot) => {
    //   setProduct({ ...snapshot.data(), id: snapshot.id });
    let current_url = window.location.href;
    current_url = current_url.split("/");
    //console.log(current_url[4]);
    const url = props.productId
      ? "https://ecommv2.servingintel.com/items/item/" +
        siteId +
        "/" +
        props.productId +
        "?server_id=" +
        serverId
      : "https://ecommv2.servingintel.com/items/item_name/" +
        siteId +
        "/" +
        current_url[4].replace("-", " ");

    //const url = "/servingintel_api/public/items/item/";
    axios
      .get(url)
      .then((response) => {
        const m_url =
          "https://ecommv2.servingintel.com/items/mods/" + siteId + "/";
        //const m_url = "/servingintel_api/public/items/mods/";
        response.data.map((item, i) => {
          axios
            .get(m_url + item.ItemID + "?server_id=" + serverId)
            .then((res_mod) => {
              item.mods = res_mod.data;
              item.revenueCenter = props.revenueCenter;
              item.menuName = props.menuName;
              setProduct(item);
              item.mods.map((mod) => {
                if (mod.ModifierMinSelect > 0) {
                  setRequired((required) => [...required, mod.ItemID]);
                }
              });
            })
            .catch((error) => {});
        });
      })
      .catch((error) => {});
  }, [updateTime]);

  useEffect(() => {
    setUpdateTime(props.updateTime);
  }, [props.updateTime]);

  // useEffect(() => {
  //   var source3 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/getItemDetailsUpdate/" +
  //       match.params.productId
  //   );
  //   source3.onmessage = function (event) {
  //     let json = decodeEntities(event.data);
  //     //let d_item = decodeEntities();
  //     console.log(json);
  //     console.log(defaultItem);
  //     //console.log(json.localeCompare(d_item));
  //     if (json.localeCompare(defaultItem) !== 0) {
  //       //setDefaultChange(event.data);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   var source3 = new EventSource(
  //     "https://ecommv2.servingintel.com/Items/getItemModsUpdate/" +
  //       match.params.productId
  //   );
  //   source3.onmessage = function (event) {
  //     if (event.data.localeCompare(defaultMod) !== 0) {
  //       setDefaultChange(event.data);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (cartItems.length) {
      const findProduct = cartItems.find(
        (item) => item.ItemID === match.params?.ItemID
      );
      if (findProduct) {
        setModifiers(findProduct.modifiers);
      }
    }
  }, [cartItems]);

  // useEffect(() => {
  //   const unsub = productRef.collection("ratings").onSnapshot((snapshot) => {
  //     if (snapshot.empty) {
  //       return;
  //     }
  //     let votes = snapshot.docs.length;
  //     let total = 0;
  //     snapshot.docs.forEach((doc) => {
  //       total += doc.data().rating;
  //     });

  //     setProductRating(parseFloat((total / votes).toFixed(1)));
  //   });

  //   return unsub;
  // }, []);

  useEffect(() => {
    const checkIfFavorite = localStorage.getItem(
      `favorites_${match.params.restaurantId}`
    );
    if (checkIfFavorite) {
      const parsedFavorites = JSON.parse(checkIfFavorite);
      setRestaurantFavorites(parsedFavorites);

      const isInFavorite = parsedFavorites.find(
        (favorite) => favorite.id === match.params.productId
      );

      if (isInFavorite) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, []);
  var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement("div");

    function decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }

      return str;
    }

    return decodeHTMLEntities;
  })();
  return (
    <>
      <Dialog
        open={true}
        onClose={() => history.goBack()}
        fullWidth
        maxWidth={"md"}
        fullScreen={isMobile}
        style={{ borderRadius: "0px" }}
        scroll="paper"
      >
        {product ? (
          <Grid container>
            <Grid item xs={12} style={{ position: isMobile ? "" : "relative" }}>
              <DialogTitle className={classes.dialogTitleBar}>
                <Typography
                  variant="h5"
                  style={{ fontWeight: "800", marginBottom: "1rem" }}
                >
                  {product.ItemName}
                </Typography>
                {/*<Typography variant="h6">
                  $ {numberWithCommas(product.DefaultPrice / 100)}
        </Typography> */}
                <IconButton
                  onClick={() => history.goBack()}
                  className={classes.close}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {product.ItemDescription}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>

                <div style={{ margin: "1rem 0rem" }}>
                  {product.mods
                    ? product.mods.map((mod, i) => {
                        return (
                          <div>
                            {mod.ModifierMaxSelect == 1 ? (
                              <FormControl fullWidth>
                                <InputLabel>
                                  {mod.ModifierMinSelect > 0
                                    ? parseHtml(
                                        mod.ModifierDescription +
                                          " <span>*</span>"
                                      )
                                    : mod.ModifierDescription}
                                </InputLabel>

                                <Select
                                  id={mod.ItemID}
                                  autoWidth="true"
                                  onChange={(e) => {
                                    if (mod.ModifierMinSelect > 0) {
                                      if (e.target.value !== "") {
                                        setRequired(
                                          required.filter(
                                            (i) => i !== e.target.value.ParentID
                                          )
                                        );
                                      } else {
                                        setRequired((required) => [
                                          ...required,
                                          e.nativeEvent.target.id,
                                        ]);
                                      }
                                    }

                                    setModifiers(
                                      modifiers.filter(
                                        (i) =>
                                          i.ParentID !== e.target.value.ParentID
                                      )
                                    );

                                    setModifiers((modifiers) => [
                                      ...modifiers,
                                      e.target.value,
                                    ]);
                                  }}
                                >
                                  <MenuItem value="" id={mod.ItemID}>
                                    <em>--Select --</em>
                                  </MenuItem>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <MenuItem value={sub_mod}>
                                            {sub_mod.DefaultPrice > 0
                                              ? `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                } + $ ${numberWithCommas(
                                                  sub_mod.DefaultPrice / 100
                                                )}`
                                              : `${
                                                  sub_mod.ModifierDescription
                                                    ? sub_mod.ModifierDescription
                                                    : sub_mod.ItemDescription
                                                }`}
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </Select>
                              </FormControl>
                            ) : (
                              <p>
                                <FormGroup style={{ marginTop: "5%" }}>
                                  <InputLabel>
                                    {mod.ModifierMinSelect > 0
                                      ? mod.ModifierDescription
                                        ? parseHtml(
                                            mod.ModifierDescription +
                                              " <span>*</span>"
                                          )
                                        : parseHtml(
                                            mod.ItemDescription +
                                              " <span>*</span>"
                                          )
                                      : mod.ModifierDescription
                                      ? mod.ModifierDescription
                                      : mod.ItemDescription}
                                  </InputLabel>
                                  {mod.sub_mods
                                    ? mod.sub_mods.map((sub_mod) => {
                                        return (
                                          <FormControlLabel
                                            value=""
                                            control={
                                              <Checkbox
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setModifiers([
                                                      ...modifiers,
                                                      sub_mod,
                                                    ]);
                                                    setRequired(
                                                      required.filter(
                                                        (i) =>
                                                          i !== sub_mod.ParentID
                                                      )
                                                    );
                                                  } else {
                                                    setModifiers(
                                                      modifiers.filter(
                                                        (i) =>
                                                          i.ItemName !==
                                                          sub_mod.ItemName
                                                      )
                                                    );
                                                    setRequired((required) => [
                                                      ...required,
                                                      sub_mod.ParentID,
                                                    ]);
                                                  }
                                                }}
                                                checked={modifiers.some(
                                                  (m) =>
                                                    m.ItemName ===
                                                    sub_mod.ItemName
                                                )}
                                              />
                                            }
                                            label={
                                              sub_mod.DefaultPrice > 0
                                                ? `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  } + $ ${numberWithCommas(
                                                    sub_mod.DefaultPrice / 100
                                                  )}`
                                                : `${
                                                    sub_mod.ModifierDescription
                                                      ? sub_mod.ModifierDescription
                                                      : sub_mod.ItemDescription
                                                  }`
                                            }
                                          />
                                        );
                                      })
                                    : null}
                                </FormGroup>
                              </p>
                            )}
                          </div>
                        );
                      })
                    : null}
                </div>

                {/* <Paper
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: isMobile ? "fixed" : "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                    zIndex: 1000,
                    padding: "1rem",
                    top: "95%",
                  }}
                  elevation={0}
                > */}
                {required.length > 0 ? (
                  <Alert severity="warning">
                    Field(s) with "*" is required.
                  </Alert>
                ) : null}
                <Grid container gap={1}>
                  <Grid item xs={6}>
                    <div style={{ border: "2px solid black", height: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            if (quantity > 1) {
                              setQuantity(quantity - 1);
                            }
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <div style={{ margin: "0rem 1rem" }}>{quantity}</div>
                        <IconButton onClick={() => setQuantity(quantity + 1)}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      style={{
                        height: "100%",
                        borderRadius: "0px",
                        textTransform: "none",
                      }}
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        // if (required.length > 0) {
                        //   document.getElementById(required[0]).scrollIntoView();
                        // }
                        if (required.length == 0) {
                          //console.log(cartItems, product, "match");
                          const findProduct = cartItems.find(
                            (item) =>
                              item.ItemID === product.ItemID &&
                              item.mods &&
                              item.mods.every((modifier) =>
                                modifiers.find(
                                  (mod) => mod.ItemName === modifier.ItemName
                                )
                              ) &&
                              (item?.mods?.length === modifiers?.length ||
                                !item.mods ||
                                (!modifiers?.length && !item?.mods?.length))
                          );
                          if (!findProduct) {
                            delete product.mods;
                            dispatch({
                              type: "SET_CART",
                              payload: [
                                ...cartItems,
                                {
                                  ...product,
                                  quantity: quantity,
                                  modifiers: modifiers,
                                  comments: "",
                                },
                              ],
                            });
                          } else {
                            let newCartItems = cartItems.map((item) => {
                              if (
                                //item.ItemID === findproduct.ItemID &&
                                (item.mods.every((modifier) =>
                                  modifiers.find(
                                    (mod) => mod.ItemName === modifier.ItemName
                                  )
                                ) &&
                                  item.mods.length === modifiers.length) || //item.ItemID === findproduct.ItemID &&
                                (item.mods.length === 0 &&
                                  modifiers.length === 0)
                              ) {
                                delete item.mods;
                                return {
                                  ...item,
                                  quantity: item.quantity + quantity,
                                  comments: "",
                                };
                              }
                              return item;
                            });

                            dispatch({
                              type: "SET_CART",
                              payload: newCartItems,
                            });
                          }
                          toast.success(
                            `Added ${product.ItemDescription} to checkout`,
                            { autoClose: 15000 }
                          );
                          history.goBack();
                        }
                      }}
                    >
                      ADD TO CHECK
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "5%" }} variant="middle" />
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subtitle1">
                          {product.ItemMessages
                            ? parseHtml(
                                decodeEntities(
                                  product.ItemMessages.replace(
                                    /table/g,
                                    "table style='border: 1px solid #000'"
                                  ).replace(
                                    /td/g,
                                    "td style='border: 1px solid #000'"
                                  )
                                )
                              )
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                {/* </Paper> */}
              </DialogContent>

              <div className={classes.toolbar} />
            </Grid>
          </Grid>
        ) : null}
      </Dialog>
      <ProductRating open={open} setOpen={setOpen} />
    </>
  );
};

export const ProductRating = ({ open, setOpen }) => {
  const [rating, setRating] = useState(0);
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [sumRating, setSumRating] = useState(0);
  const [usersRated, setUsersRated] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const productRef = db
    .collection("admins")
    .doc(match.params.restaurantId)
    .collection("products")
    .doc(match.params.productId);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Rate This Product</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(5).fill("star").map((star, index) => {
            return (
              <IconButton onClick={() => setRating(index + 1)}>
                {index + 1 > rating ? (
                  <StarBorderIcon
                    style={{ margin: !isMobile && "1rem" }}
                    fontSize="large"
                  />
                ) : (
                  <StarIcon
                    style={{ margin: !isMobile && "1rem", color: "yellow" }}
                    fontSize="large"
                  />
                )}
              </IconButton>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(setLoading(true));
            productRef
              .collection("ratings")
              .add({ rating })
              .then(() => {
                dispatch(setLoading(false));
                setOpen(false);
              });
          }}
        >
          Save Rating
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const BottomNavRestaurant = () => {
  const history = useHistory();
  const { currentRestaurant } = useSelector((state) => state.public);
  const match = useRouteMatch();
  return (
    <Paper
      elevation={10}
      style={{
        position: "fixed",
        bottom: "0px",
        width: "100%",
        display: "none",
      }}
    >
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label="Back"
          icon={<ArrowBackIcon onClick={() => history.goBack()} />}
        />
        <BottomNavigationAction label="Account" icon={<PersonIcon />} />
        <BottomNavigationAction label="Rewards" icon={<MonetizationOnIcon />} />
        <BottomNavigationAction
          label="Favorites"
          icon={
            <FavoriteIcon
              onClick={() =>
                history.push(`/favorites/${match.params.restaurantId}`)
              }
            />
          }
        />
        <BottomNavigationAction
          label="Chat"
          icon={<CustomerChat restaurant={currentRestaurant.restaurantName} />}
        />
      </BottomNavigation>
    </Paper>
  );
};
